var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen flex w-full bg-img vx-row no-gutter sm:items-center justify-center sm:pt-16",attrs:{"id":"page-login"}},[_c('div',{staticClass:"vx-col w-full md:w-1/2 lg:w-3/4 xl:w-3/5 m-0 sm:m-4"},[_c('vx-card',[_c('div',{staticClass:"full-page-bg-color min-h-screen sm:min-h-full",attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"vx-row no-gutter justify-center items-center"},[_c('div',{staticClass:"vx-col hidden w-full lg:block lg:w-1/2"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("@/assets/images/logo/logo.webp")}}),_c('source',{attrs:{"type":"image/png","srcset":require("@/assets/images/logo/logo.png")}}),_c('img',{staticClass:"mx-auto",staticStyle:{"width":"80%"},attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":"login"}})])]),_c('div',{staticClass:"vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg p-4 sm:p-0"},[_c('div',{staticClass:"p-8 login-tabs-container"},[_c('div',{staticClass:"vx-card__title mb-4"},[_c('h4',{staticClass:"mb-12"},[_vm._v(" Broker "),_c('strong',[_vm._v("Account")])])]),_c('vs-tabs',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show.accessRegister),expression:"!show.accessRegister"}],ref:"1",attrs:{"value":_vm.$store.getters.loginTabIndex}},[_c('vs-tab',{attrs:{"label":"Client Tools"}},[_c('vs-input',{staticClass:"w-full mb-12 mt-10",attrs:{"name":"email","icon-no-border":"","icon":"icon icon-terminal","icon-pack":"feather","label":"Access Code","size":_vm.$store.getters
                                                .windowBreakPoint === 'xs'
                                                ? 'large'
                                                : null,"danger":_vm.accessCodeError &&
                                            _vm.accessCodeError.detail,"danger-text":_vm.accessCodeError &&
                                            _vm.accessCodeError.detail,"danger-color":"secondary","description-text":"This is the code\n                                    that you would normally use to send\n                                    vouchers, start a will or refer a client"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.checkAccessCode.apply(null, arguments)}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{staticClass:"flex flex-row-reverse mt-6"},[_c('vs-button',{staticClass:"float-right w-full sm:w-1/3",attrs:{"size":_vm.$store.getters
                                                    .windowBreakPoint ===
                                                'xs'
                                                    ? 'large'
                                                    : null},on:{"click":_vm.checkAccessCode}},[_vm._v("Continue")])],1)],1),_c('vs-tab',{attrs:{"label":"Broker Management"}},[_c('form',{attrs:{"id":"login"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.authenticate.apply(null, arguments)}}},[_c('vs-input',{staticClass:"w-full",attrs:{"name":"email","icon-no-border":"","icon":"icon icon-user","icon-pack":"feather","label":"Email","danger":_vm.loginError &&
                                                _vm.loginError.detail,"danger-text":_vm.loginError &&
                                                _vm.loginError.detail,"danger-color":"secondary","size":_vm.$store.getters
                                                    .windowBreakPoint ===
                                                'xs'
                                                    ? 'large'
                                                    : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('vs-input',{staticClass:"w-full mt-6 pb-5",attrs:{"type":"password","name":"password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label":"Password","size":_vm.$store.getters
                                                    .windowBreakPoint ===
                                                'xs'
                                                    ? 'large'
                                                    : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"sup-footer"},[_c('p',[_c('a',{attrs:{"href":_vm.resetPasswordLink}},[_vm._v("Forgotten Password")])])]),_c('div',{staticClass:"flex flex-row-reverse mt-6"},[_c('vs-button',{staticClass:"float-right w-full sm:w-1/3",attrs:{"size":_vm.$store.getters
                                                        .windowBreakPoint ===
                                                    'xs'
                                                        ? 'large'
                                                        : null},on:{"click":_vm.authenticate}},[_vm._v("Login")])],1)],1)])],1),_c('vs-tabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.accessRegister),expression:"show.accessRegister"}],ref:"2"},[_c('vs-tab',{attrs:{"label":"Register"}},[(
                                            _vm.registerAccessRequestDetails &&
                                            _vm.registerAccessRequestDetails.email
                                        )?_c('vs-alert',[_vm._v(_vm._s(_vm.registerAccessRequestDetails.email))]):_vm._e(),_c('form',{attrs:{"id":"register"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.registerAccessRequest.apply(null, arguments)}}},[_c('vs-input',{staticClass:"w-full mt-6 pb-5",attrs:{"type":"password","name":"password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label":"Password","danger":_vm.errorsRegisterAccessRequest.password1 !==
                                                null,"danger-text":_vm.errorsRegisterAccessRequest.password1,"size":_vm.$store.getters
                                                    .windowBreakPoint ===
                                                'xs'
                                                    ? 'large'
                                                    : null},model:{value:(_vm.register.password1),callback:function ($$v) {_vm.$set(_vm.register, "password1", $$v)},expression:"register.password1"}}),_c('vs-input',{staticClass:"w-full mt-6 pb-5",attrs:{"type":"password","name":"password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label":"Repeat Password","danger":_vm.errorsRegisterAccessRequest.password2 !==
                                                null,"danger-text":_vm.errorsRegisterAccessRequest.password2,"size":_vm.$store.getters
                                                    .windowBreakPoint ===
                                                'xs'
                                                    ? 'large'
                                                    : null},model:{value:(_vm.register.password2),callback:function ($$v) {_vm.$set(_vm.register, "password2", $$v)},expression:"register.password2"}}),_c('div',{staticClass:"flex flex-row-reverse mt-6"},[_c('vs-button',{staticClass:"float-right w-full sm:w-1/3",attrs:{"disabled":!_vm.validRegisterAccessCode,"size":_vm.$store.getters
                                                        .windowBreakPoint ===
                                                    'xs'
                                                        ? 'large'
                                                        : null},on:{"click":_vm.registerAccessRequest}},[_vm._v("Register")])],1)],1)],1)],1)],1)])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }