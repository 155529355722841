<template>
    <div
        class="h-screen flex w-full bg-img vx-row no-gutter sm:items-center justify-center sm:pt-16"
        id="page-login"
    >
        <div class="vx-col w-full md:w-1/2 lg:w-3/4 xl:w-3/5 m-0 sm:m-4">
            <vx-card>
                <div
                    slot="no-body"
                    class="full-page-bg-color min-h-screen sm:min-h-full"
                >
                    <div class="vx-row no-gutter justify-center items-center">
                        <div class="vx-col hidden w-full lg:block lg:w-1/2">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="@/assets/images/logo/logo.webp"
                                />
                                <source
                                    type="image/png"
                                    srcset="@/assets/images/logo/logo.png"
                                />
                                <img
                                    src="@/assets/images/logo/logo.png"
                                    alt="login"
                                    class="mx-auto"
                                    style="width: 80%"
                                />
                            </picture>
                        </div>

                        <div
                            class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg p-4 sm:p-0"
                        >
                            <div class="p-8 login-tabs-container">
                                <div class="vx-card__title mb-4">
                                    <h4 class="mb-12">
                                        Broker <strong>Account</strong>
                                    </h4>
                                </div>
                                <vs-tabs
                                    :value="$store.getters.loginTabIndex"
                                    ref="1"
                                    v-show="!show.accessRegister"
                                >
                                    <vs-tab label="Client Tools">
                                        <vs-input
                                            name="email"
                                            icon-no-border
                                            icon="icon icon-terminal"
                                            icon-pack="feather"
                                            label="Access Code"
                                            v-model="code"
                                            @keyup.enter="checkAccessCode"
                                            :size="
                                                $store.getters
                                                    .windowBreakPoint === 'xs'
                                                    ? 'large'
                                                    : null
                                            "
                                            class="w-full mb-12 mt-10"
                                            :danger="
                                                accessCodeError &&
                                                accessCodeError.detail
                                            "
                                            :danger-text="
                                                accessCodeError &&
                                                accessCodeError.detail
                                            "
                                            danger-color="secondary"
                                            description-text="This is the code
                                        that you would normally use to send
                                        vouchers, start a will or refer a client"
                                        />

                                        <div class="flex flex-row-reverse mt-6">
                                            <vs-button
                                                @click="checkAccessCode"
                                                class="float-right w-full sm:w-1/3"
                                                :size="
                                                    $store.getters
                                                        .windowBreakPoint ===
                                                    'xs'
                                                        ? 'large'
                                                        : null
                                                "
                                                >Continue</vs-button
                                            >
                                        </div>
                                    </vs-tab>

                                    <vs-tab label="Broker Management">
                                        <form
                                            id="login"
                                            @keyup.enter="authenticate"
                                        >
                                            <vs-input
                                                name="email"
                                                icon-no-border
                                                icon="icon icon-user"
                                                icon-pack="feather"
                                                label="Email"
                                                v-model="email"
                                                class="w-full"
                                                :danger="
                                                    loginError &&
                                                    loginError.detail
                                                "
                                                :danger-text="
                                                    loginError &&
                                                    loginError.detail
                                                "
                                                danger-color="secondary"
                                                :size="
                                                    $store.getters
                                                        .windowBreakPoint ===
                                                    'xs'
                                                        ? 'large'
                                                        : null
                                                "
                                            />

                                            <vs-input
                                                type="password"
                                                name="password"
                                                icon-no-border
                                                icon="icon icon-lock"
                                                icon-pack="feather"
                                                label="Password"
                                                v-model="password"
                                                class="w-full mt-6 pb-5"
                                                :size="
                                                    $store.getters
                                                        .windowBreakPoint ===
                                                    'xs'
                                                        ? 'large'
                                                        : null
                                                "
                                            />

                                            <div class="sup-footer">
                                                <p>
                                                    <a :href="resetPasswordLink"
                                                    >Forgotten Password</a
                                                    >
                                                </p>
                                            </div>

                                            <div
                                                class="flex flex-row-reverse mt-6"
                                            >
                                                <vs-button
                                                    @click="authenticate"
                                                    class="float-right w-full sm:w-1/3"
                                                    :size="
                                                        $store.getters
                                                            .windowBreakPoint ===
                                                        'xs'
                                                            ? 'large'
                                                            : null
                                                    "
                                                    >Login</vs-button
                                                >
                                            </div>
                                        </form>
                                    </vs-tab>
                                </vs-tabs>
                                <vs-tabs ref="2" v-show="show.accessRegister">
                                    <vs-tab label="Register">
                                        <vs-alert
                                            v-if="
                                                registerAccessRequestDetails &&
                                                registerAccessRequestDetails.email
                                            "
                                            >{{
                                                registerAccessRequestDetails.email
                                            }}</vs-alert
                                        >
                                        <form
                                            id="register"
                                            @keyup.enter="registerAccessRequest"
                                        >
                                            <vs-input
                                                type="password"
                                                name="password"
                                                icon-no-border
                                                icon="icon icon-lock"
                                                icon-pack="feather"
                                                label="Password"
                                                :danger="
                                                    errorsRegisterAccessRequest.password1 !==
                                                    null
                                                "
                                                :danger-text="
                                                    errorsRegisterAccessRequest.password1
                                                "
                                                v-model="register.password1"
                                                class="w-full mt-6 pb-5"
                                                :size="
                                                    $store.getters
                                                        .windowBreakPoint ===
                                                    'xs'
                                                        ? 'large'
                                                        : null
                                                "
                                            />
                                            <vs-input
                                                type="password"
                                                name="password"
                                                icon-no-border
                                                icon="icon icon-lock"
                                                icon-pack="feather"
                                                label="Repeat Password"
                                                :danger="
                                                    errorsRegisterAccessRequest.password2 !==
                                                    null
                                                "
                                                :danger-text="
                                                    errorsRegisterAccessRequest.password2
                                                "
                                                v-model="register.password2"
                                                class="w-full mt-6 pb-5"
                                                :size="
                                                    $store.getters
                                                        .windowBreakPoint ===
                                                    'xs'
                                                        ? 'large'
                                                        : null
                                                "
                                            />

                                            <div
                                                class="flex flex-row-reverse mt-6"
                                            >
                                                <vs-button
                                                    :disabled="
                                                        !validRegisterAccessCode
                                                    "
                                                    @click="
                                                        registerAccessRequest
                                                    "
                                                    class="float-right w-full sm:w-1/3"
                                                    :size="
                                                        $store.getters
                                                            .windowBreakPoint ===
                                                        'xs'
                                                            ? 'large'
                                                            : null
                                                    "
                                                    >Register</vs-button
                                                >
                                            </div>
                                        </form>
                                    </vs-tab>
                                </vs-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            code: null,
            registerAccessRequestDetails: null,
            register: {
                access_request_code: null,
                password1: null,
                password2: null,
            },
            errorsRegisterAccessRequest: {
                password1: null,
                password2: null,
            },
            show: {
                accessRegister: false,
            },
        }
    },
    created() {
        this.$root.$on('showLoginTab', this.showLoginTab)

        if (this.$route.params.code) {
            this.$store.dispatch('logout')
            this.registerAccessRequestCheck(this.$route.params.code)
        }
        if (this.$route.params.access && this.$route.params.refresh) {
            // this.$store.dispatch('logout')
            var data = {
                access: this.$route.params.access,
                refresh: this.$route.params.refresh,
            }
            this.$store.dispatch('_setTokens', data)
        } else {
            this.$store.dispatch('inspectToken')
        }
    },
    watch: {
        tab: {
            deep: true,
            immediate: true,
            handler(value) {
                console.log('watch tab changed', value)
                if (value === 0 && this.tab === 1) this.tab = 1
            },
        },
        loginError: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value && value.detail)
                    this.$vs.notify({
                        title: 'Login Error',
                        text: value.detail,
                        color: 'warning',
                        position: 'top-center',
                        time: 6000,
                    })
            },
        },
        accessCodeError: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value && value.detail)
                    this.$vs.notify({
                        title: 'Access Code Error',
                        text: value.detail,
                        color: 'warning',
                        position: 'top-center',
                        time: 6000,
                    })
            },
        },
    },
    computed: {
        accessCodeError() {
            return this.$store.getters.accessCodeError
        },
        loginError() {
            return this.$store.getters.loginError
        },
        validRegisterAccessCode() {
            return !!(
                this.registerAccessRequestDetails &&
                this.registerAccessRequestDetails.code &&
                this.register.password1 &&
                this.register.password2 &&
                this.register.password1 === this.register.password2
            )
        },
        resetPasswordLink () {
            if (process.env.NODE_ENV === 'production') return 'https://www.mylastwill.co.uk/accounts/password_reset'
            return 'http://127.0.0.1:8000/accounts/password_reset'
        }
    },
    methods: {
        checkAccessCode() {
            if (this.code) {
                this.$store.commit('accessCodeError', null)
                this.$store.dispatch('obtainAccessCode', this.code)
            }
        },
        authenticate() {
            if (this.email && this.password) {
                this.$store.commit('loginError', null)
                this.$store.dispatch('obtainToken', {
                    email: this.email,
                    password: this.password,
                })
            }
        },
        registerAccessRequestCheck(accessRequestCode) {
            http.get('register_access_request', {
                params: {
                    access_request_code: accessRequestCode,
                },
            })
                .then((response) => {
                    this.show.accessRegister = true
                    this.registerAccessRequestDetails = response.data
                    this.register.access_request_code = response.data.code
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-user',
                        position: 'top-center',
                        time: 7000,
                    })
                    this.$router
                        .push({ name: 'page-login' })
                        .catch((e) => console.log(e))
                })
        },

        registerAccessRequest() {
            this.clearRegisterAccessRequestErrors()
            http.post('register_access_request', this.register)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Access created',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-user',
                        position: 'top-center',
                        time: 7000,
                    })
                    this.$store.dispatch('_setTokens', response.data)
                })
                .catch((error) => {
                    console.log(error)
                    this.handleRegisterAccessRequestErrors(error.response.data)
                    this.$vs.notify({
                        title: 'Error',
                        text: 'Register Error',
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-user',
                        position: 'top-center',
                        time: 7000,
                    })
                })
        },
        handleRegisterAccessRequestErrors(errors) {
            if (errors.password1)
                this.errorsRegisterAccessRequest.password1 = errors.password1[0]
            if (errors.password2)
                this.errorsRegisterAccessRequest.password1 = errors.password2[0]
            if (errors.new_password1)
                this.errorsRegisterAccessRequest.password1 =
                    errors.new_password1[0]
            if (errors.new_password2)
                this.errorsRegisterAccessRequest.password1 =
                    errors.new_password2[0]
        },
        clearRegisterAccessRequestErrors() {
            this.errorsRegisterAccessRequest.password1 = null
            this.errorsRegisterAccessRequest.password2 = null
        },
    },
}
</script>

<style lang="scss">
.login-tabs-container {
    min-height: 505px;

    .con-tab {
        padding-bottom: 14px;
    }

    .con-slot-tabs {
        margin-top: 1rem;
    }
}
</style>
